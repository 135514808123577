import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
<script
  src="https://kit.fontawesome.com/79e6297b37.js"
  crossorigin="anonymous"
></script>;


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
      <HelmetProvider>
      <App />
      </HelmetProvider>
    </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
