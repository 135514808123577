import React from 'react'
import ContactHeader from '../component/ContactUsPage/Contact'
import ContactMain from '../component/ContactUsPage/contactMain'

function Contact() {
  return (
    <div>
      <ContactHeader />
      <ContactMain />
    </div>
  )
}

export default Contact